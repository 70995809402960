import wkt from "wkt";
import {gql} from "@apollo/client";
import exifr from "exifr";
import {DateTime} from "luxon";
import {
  area as turfArea,
  buffer as turfBuffer,
  booleanPointInPolygon,
  type Coord,
  type Feature,
} from "@turf/turf";
import type {Polygon} from "geojson";

import {client} from "../config/apollo";
import type {UploadRawFile} from "../upload/redux/uploadRawFilesSlice";
import type {UploadMosaicPart} from "../upload/redux/uploadMosaicPartsSlice";

export const errorMessage = (message?: string) => {
  switch (message) {
    case "API_INCORRECT_USERNAME_PASSWORD":
      return "Invalid email or password.";

    default:
      return "Something went wrong! Please try again later.";
  }
};

export const cls = (input: string) =>
  input
    .replace(/\s+/gm, " ")
    .split(" ")
    .filter(cond => typeof cond === "string")
    .join(" ")
    .trim();

// TODO - Add support for multi polygons
export const polygonToStr = (polygon: Polygon): string => {
  let coordinates = polygon.coordinates;

  if (coordinates.length > 1) {
    let area = 0;

    for (let i = 0; i < coordinates.length; i++) {
      const cArea = turfArea({
        type: "Polygon",
        coordinates: [coordinates[i]],
      });

      if (cArea > area) {
        area = cArea;
        coordinates = [coordinates[i]];
      }
    }
  }

  const wktStr = coordinates
    .map(ring => `(${ring.map(p => `(${p[1]}, ${p[0]})`).join(", ")})`)
    .join(", ");

  return wktStr;
};

export const polygonToWkt = (polygon): string | null => {
  if (polygon?.type === "Polygon") {
    return wkt.stringify(polygon);
  }

  if (polygon?.geometry_wkt) {
    return polygon.geometry_wkt;
  }

  if (polygon?.geometry) {
    const geom_arr = polygon.geometry
      .replaceAll("((", "")
      .replaceAll("))", "")
      .split("),(");

    let wktStr = "POLYGON((";

    for (let index = 0; index < geom_arr.length; index++) {
      const coords = geom_arr[index].split(",");
      wktStr += coords[1] + " " + coords[0] + ",";
      if (index == geom_arr.length - 1) {
        wktStr += coords[1] + " " + coords[0];
      }
    }

    wktStr += "))";

    return wktStr;
  }

  return null;
};

export const wktToPolygon = (polygon, boundaryOnly = false): Polygon | null => {
  if (typeof polygon === "string") {
    return wkt.parse(polygon);
  }

  if (polygon?.geometry_wkt && !boundaryOnly) {
    return wkt.parse(polygon.geometry_wkt);
  }

  if (polygon?.geometry) {
    return {
      type: "Polygon",
      coordinates: [
        polygon.geometry
          .replaceAll("((", "")
          .replaceAll("))", "")
          .split("),(")
          .map(coords =>
            coords
              .split(",")
              .map(coord => Number(coord))
              .reverse(),
          ),
      ],
    };
  }

  return null;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const LEAP_SECONDS = [
  new Date("1980-01-06T00:00:00.000Z"),
  new Date("1981-07-01T00:00:00.000Z"),
  new Date("1982-07-01T00:00:00.000Z"),
  new Date("1983-07-01T00:00:00.000Z"),
  new Date("1985-07-01T00:00:00.000Z"),
  new Date("1988-01-01T00:00:00.000Z"),
  new Date("1990-01-01T00:00:00.000Z"),
  new Date("1991-01-01T00:00:00.000Z"),
  new Date("1992-07-01T00:00:00.000Z"),
  new Date("1993-07-01T00:00:00.000Z"),
  new Date("1994-07-01T00:00:00.000Z"),
  new Date("1996-01-01T00:00:00.000Z"),
  new Date("1997-07-01T00:00:00.000Z"),
  new Date("1999-01-01T00:00:00.000Z"),
  new Date("2006-01-01T00:00:00.000Z"),
  new Date("2009-01-01T00:00:00.000Z"),
  new Date("2012-07-01T00:00:00.000Z"),
  new Date("2015-07-01T00:00:00.000Z"),
  new Date("2017-01-01T00:00:00.000Z"),
  new Date("2018-01-01T00:00:00.000Z"),
  new Date("2019-01-01T00:00:00.000Z"),
].reverse();

export const gpsToUtc = (gpsWeek: number, gpsSeconds: number) => {
  // Constants
  const SECONDS_PER_WEEK = 604800; // 60 * 60 * 24 * 7
  const GPS_EPOCH = new Date("1980-01-06T00:00:00.000Z");

  // Calculate the total number of seconds since GPS epoch
  const totalSeconds = gpsWeek * SECONDS_PER_WEEK + gpsSeconds;

  // Calculate GPS time without leap seconds
  const gpsTime = new Date(GPS_EPOCH.getTime() + totalSeconds * 1000);

  // Add leap seconds
  let leapOffset = 0;

  for (let i = 0; i < LEAP_SECONDS.length; i++) {
    const year = LEAP_SECONDS[i];

    // @ts-expect-error athematic operation on date
    if (gpsTime - year > 0) {
      leapOffset += LEAP_SECONDS.length - i;
      break;
    }
  }

  // Calculate the total number of seconds since GPS epoch considering leap seconds
  const totalSecondsWithLeap = totalSeconds - leapOffset;

  // Calculate UTC time with leap seconds
  const utcTime = new Date(GPS_EPOCH.getTime() + totalSecondsWithLeap * 1000);

  return utcTime;
};

export const isPointInPolygon = (
  point: Coord,
  polygon: Feature<Polygon> | Polygon,
) => {
  let isInPolygon = false;

  try {
    isInPolygon = booleanPointInPolygon(point, polygon as Polygon);
  } catch (e) {
    // Invalid point or polygon
  }

  return isInPolygon;
};

export const bufferPolygon = (polygon: Polygon, buffer: number) => {
  let bufferedPolygon: Feature<Polygon> | null = null;

  try {
    bufferedPolygon = turfBuffer(polygon, buffer, {units: "meters"});
  } catch (e) {
    // Invalid point or polygon
  }

  return bufferedPolygon;
};

export const parseRawUploadData = async (blobs: File[], userId: string) => {
  // Extract jpg and tiff files
  const imageBlobs = blobs.filter(blob => {
    const fileName = blob.name.slice().toUpperCase();

    return (
      (fileName.endsWith(".JPG") ||
        fileName.endsWith(".TIF") ||
        fileName.endsWith(".JPEG") ||
        fileName.endsWith(".TIFF")) &&
      blob.name[0]?.match(/^[a-z0-9]+$/i)
    );
  });

  // Files with metadata
  let uploadFiles: UploadRawFile[] = [];

  // Blobs by id
  const uploadBlobs: Record<string, File> = {};

  for (const blob of imageBlobs) {
    // Get image metadata
    const geoLocation = await exifr.gps(blob).catch(() => null);

    // Add file to uploads if it has gps coordinates
    if (geoLocation?.latitude && geoLocation?.longitude) {
      const file = {
        id: blob.webkitRelativePath,
        name: blob.name,
        path: blob.webkitRelativePath,
        size: blob.size,
        status: "idle",
        fields: [],
        selected: false,
        metadata: {
          latitude: geoLocation.latitude,
          longitude: geoLocation.longitude,
        },
      };

      uploadFiles.push(file);
      uploadBlobs[file.id] = blob;
    }
  }

  // Get user fields
  const getFieldsResult = await client.query({
    query: gql(/* GraphQL */ `
      query GetUserFields {
        field_season_shot(
          where: {farm: {userId: {_eq: "${userId}"}}}
          distinct_on: fieldId
        ) {
          id
          name
          farmId
          cropName
          polygon {
            geometry
            geometry_wkt
          }
        }
      }
    `),
  });

  if (!getFieldsResult.data?.field_season_shot?.length) {
    throw new Error("Please add farm and fields before uploading data");
  }

  getFieldsResult.data?.field_season_shot?.forEach(field => {
    const polygon = wktToPolygon(field.polygon);
    const bufferedPolygon = polygon && bufferPolygon(polygon, 20);

    if (bufferedPolygon) {
      uploadFiles.forEach((file, i) => {
        // Add file, if the field polygon contains the file gps coordinates
        if (
          isPointInPolygon(
            [file.metadata.longitude, file.metadata.latitude],
            bufferedPolygon,
          )
        ) {
          uploadFiles[i].fields.push(field.id); // Add field to the upload file
        }
      });
    }
  });

  // Remove upload files with no fields
  uploadFiles = uploadFiles.filter(file => file.fields.length > 0);

  if (!uploadFiles.length) {
    throw new Error(
      "Please select data for the existing fields or create new fields to continue",
    );
  }

  // Extract obs, nav, bin and mrk files
  const extraBlobs = blobs.filter(blob => {
    const fileName = blob.name.slice().toUpperCase();

    return (
      (fileName.endsWith(".OBS") ||
        fileName.endsWith(".NAV") ||
        fileName.endsWith(".BIN") ||
        fileName.endsWith(".MRK") ||
        fileName.endsWith(".CSV")) &&
      blob.name[0]?.match(/^[a-z0-9]+$/i)
    );
  });

  extraBlobs.forEach(blob => {
    // Get directory path
    const directoryPath = blob.webkitRelativePath.slice(
      0,
      blob.webkitRelativePath.lastIndexOf("/"),
    );

    // Get upload file within the directory
    const uploadFile = uploadFiles.find(file => {
      const uploadFileDirectoryPath = file.path.slice(
        0,
        file.path.lastIndexOf("/"),
      );

      return directoryPath === uploadFileDirectoryPath;
    });

    if (uploadFile) {
      const file = {
        id: blob.webkitRelativePath,
        name: blob.name,
        path: blob.webkitRelativePath,
        size: blob.size,
        status: "idle",
        fields: uploadFile.fields,
        selected: false,
        metadata: uploadFile.metadata,
      };

      // Add file to the uploads
      uploadFiles.push(file);
      uploadBlobs[file.id] = blob;
    }
  });

  const uploadDate = await exifr.parse(imageBlobs[0], ["DateTimeOriginal"]);

  return {
    date: new Date(uploadDate["DateTimeOriginal"]).getTime(),
    files: uploadFiles,
    blobs: uploadBlobs,
  };
};

export const parseMosaicData = async (blob: File) => {
  if (blob.type !== "image/tiff") {
    throw new Error("Please select a valid GeoTIFF file.");
  }

  const uploadBlob = blob;
  const uploadParts: UploadMosaicPart[] = [];

  const partSize = 100 * 1024 * 1024; // 100 MB
  const partsCount = Math.ceil(blob.size / partSize);

  for (let i = 0; i < partsCount; i++) {
    const startAt = i * partSize;
    const endAt = Math.min(startAt + partSize, blob.size);

    uploadParts.push({
      id: i + 1,
      startAt,
      endAt,
      size: endAt - startAt,
      uploaded: false,
      uploading: false,
    });
  }

  return {
    blob: uploadBlob,
    parts: uploadParts,
  };
};

export const getHighResMapDate = highResMap => {
  let date = DateTime.fromISO(highResMap.createdAt).toJSDate();

  if (highResMap.captured_at) {
    date = DateTime.fromISO(highResMap.captured_at).toJSDate();
  }

  if (highResMap.link) {
    const dateStr = highResMap.link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  if (highResMap.ms_link) {
    const dateStr = highResMap.ms_link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  if (highResMap.ms_rapid_link) {
    const dateStr = highResMap.ms_rapid_link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  if (highResMap.rgb_link) {
    const dateStr = highResMap.rgb_link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  if (highResMap.rgb_rapid_link) {
    const dateStr = highResMap.rgb_rapid_link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  return date;
};
