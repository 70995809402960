import {
  createEntityAdapter,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";
import {rawUploadCancelled, validateRawUpload} from "./uploadSlice";

export interface UploadRawFile {
  id: string;
  name: string;
  path: string;
  size: number;
  status: string;
  fields: string[];
  metadata: {
    latitude: number;
    longitude: number;
  };
  selected: boolean;
}

const uploadRawFilesAdapter = createEntityAdapter<UploadRawFile>();

// Initial state

const initialState = uploadRawFilesAdapter.getInitialState();

// Slice
const uploadRawFilesSlice = createSlice({
  name: "uploadRawFiles",
  initialState,
  reducers: {
    filesAdded(state, action: PayloadAction<UploadRawFile[]>) {
      uploadRawFilesAdapter.setAll(state, action.payload);
    },
    filesRemoved(state, action: PayloadAction<string[]>) {
      uploadRawFilesAdapter.removeMany(state, action.payload);
    },
    fileSelected(state, action: PayloadAction<string>) {
      uploadRawFilesAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          selected: true,
        },
      });
    },
    fileUnselected(state, action: PayloadAction<string>) {
      uploadRawFilesAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          selected: false,
        },
      });
    },
    filesUploaded(state, action: PayloadAction<string[]>) {
      uploadRawFilesAdapter.updateMany(
        state,
        action.payload.map(id => ({
          id,
          changes: {
            status: "uploaded",
          },
        })),
      );
    },
    filesUploadStarted(state, action: PayloadAction<string[]>) {
      uploadRawFilesAdapter.updateMany(
        state,
        action.payload.map(id => ({
          id,
          changes: {
            status: "uploading",
          },
        })),
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(rawUploadCancelled, state => {
      uploadRawFilesAdapter.removeAll(state);
    });
    builder.addCase(validateRawUpload.fulfilled, (state, action) => {
      const files = uploadRawFilesAdapter.getSelectors().selectAll(state);

      action.payload.forEach(validatedField => {
        if (!validatedField.isValid) {
          files
            .filter(file => file.fields.includes(validatedField.field))
            .forEach((file, i) => {
              files[i].fields = file.fields.filter(
                field => field !== validatedField.field,
              );
            });
        }
      });

      const filesToUpdate = files.filter(file => file.fields.length > 0);

      if (filesToUpdate.length > 0) {
        uploadRawFilesAdapter.updateMany(
          state,
          filesToUpdate.map(file => ({
            id: file.id,
            changes: {
              fields: file.fields,
            },
          })),
        );
      }

      const filesToRemove = files
        .filter(file => file.fields.length === 0)
        .map(file => file.id);

      if (filesToRemove.length > 0) {
        uploadRawFilesAdapter.removeMany(state, filesToRemove);
      }
    });
  },
});

// Actions
export const {
  filesAdded,
  filesRemoved,
  fileSelected,
  fileUnselected,
  filesUploaded,
  filesUploadStarted,
} = uploadRawFilesSlice.actions;

// Reducer
export default uploadRawFilesSlice.reducer;

// Selectors

export const {
  selectAll: selectUploadFiles,
  selectIds: selectUploadFilesIds,
  selectById: selectUploadFileById,
  selectTotal: selectUploadFilesTotal,
} = uploadRawFilesAdapter.getSelectors<RootState>(
  state => state.uploadRawFiles ?? initialState,
);

export const selectSelectedFiles = (state: RootState) =>
  selectUploadFiles(state).filter(file => file.selected);

export const selectUploadedFiles = (state: RootState) =>
  selectUploadFiles(state).filter(file => file.status === "uploaded");

export const selectUploadedFilesIds = (state: RootState) =>
  selectUploadedFiles(state).map(file => file.id);

export const selectUploadedFilesTotal = (state: RootState) =>
  selectUploadedFiles(state).length;

export const selectUploadingFiles = (state: RootState) =>
  selectUploadFiles(state).filter(file => file.status === "uploading");

export const selectUploadMapPoints = (state: RootState) =>
  selectUploadFiles(state).filter(file => {
    const fileName = file.name.slice().toUpperCase();

    return fileName.endsWith(".JPG") || fileName.endsWith(".JPEG");
  });

export const selectUploadFields = (state: RootState) => {
  const fields: string[] = [];

  const files = selectUploadFiles(state);

  files.forEach(file => {
    file.fields.forEach(field => {
      if (!fields.includes(field)) {
        fields.push(field);
      }
    });
  });

  return fields;
};

export const selectTotalBytes = (state: RootState) =>
  selectUploadFiles(state)
    .map(file => file.size)
    .reduce((a, b) => a + b, 0);

export const selectUploadedBytes = (state: RootState) =>
  selectUploadedFiles(state)
    .map(file => file.size)
    .reduce((a, b) => a + b, 0);

export const selectFilesToUpload = (state: RootState, limit: number) => {
  if (selectUploadingFiles(state).length > 0) {
    return [];
  }

  return selectUploadFiles(state)
    .filter(file => file.status === "idle")
    .slice(0, limit);
};

export const selectFilesToUploadIds = (state: RootState, limit: number) =>
  selectFilesToUpload(state, limit).map(file => file.id);
