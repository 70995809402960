import React, {useCallback} from "react";
import {DateTime} from "luxon";
import {useNavigate} from "react-router-dom";
import {shallowEqual} from "react-redux";

import {Button, ErrorMessage, TextInput} from "../../../common";
import CloseIcon from "../../../assets/icons/edit-close.svg";
import PlusIcon from "../../../assets/icons/edit-plus-2.svg";

import {formatBytes} from "../../../util/functions";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {actionClosed} from "../../redux/farmSlice";
import {selectTotalBytes} from "../../../upload/redux/uploadRawFilesSlice";
import {
  validateRawUpload,
  rawUploadStarted,
  selectDate,
  selectError,
  selectStatus,
  selectDirectory,
} from "../../../upload/redux/uploadSlice";

interface Props {
  onClose: () => void;
  onSelectFolder: () => void;
}

/* =============================================================================
<UploadRawData />
============================================================================= */
const UploadRawData: React.FC<Props> = ({onClose, onSelectFolder}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const date = useAppSelector(selectDate, shallowEqual);
  const error = useAppSelector(selectError, shallowEqual);
  const status = useAppSelector(selectStatus, shallowEqual);
  const directory = useAppSelector(selectDirectory, shallowEqual);
  const totalBytes = useAppSelector(selectTotalBytes, shallowEqual);

  const isProcessing = status === "raw-processing";
  const disabled = isProcessing;

  const _handleCloseClick = () => {
    onClose();
  };

  const _handleSelectFolder = () => {
    onSelectFolder();
  };

  const _handleUploadClick = useCallback(async () => {
    const result = await dispatch(validateRawUpload());

    if (validateRawUpload.fulfilled.match(result)) {
      dispatch(actionClosed());
      dispatch(rawUploadStarted());
      navigate("/upload/raw");
    }
  }, [dispatch, navigate]);

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white z-10 app-shadow">
      <button
        type="button"
        className="block ml-auto p-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <div className="w-[360px] px-9 pb-10 pt-2">
        <div className="flex">
          <button
            type="button"
            className="w-12 h-12 flex items-center justify-center rounded-full bg-black"
            onClick={_handleSelectFolder}>
            <PlusIcon />
          </button>
          <div className="flex-1 pl-4">
            <h1 className="text-xl font-medium">Upload raw data</h1>
            <button
              type="button"
              className="text-sm font-medium text-[#454545]"
              onClick={_handleSelectFolder}>
              select a folder
            </button>
          </div>
        </div>
        <div className="w-full grid grid-cols-1 gap-5 mt-9">
          <TextInput
            label="Name"
            value={directory ? `${directory} (${formatBytes(totalBytes)})` : ""}
            disabled={true}
            className="py-3"
            placeholder="e.g. HighRes-2cm"
          />
          <TextInput
            label="Date"
            value={
              date
                ? DateTime.fromMillis(date).toLocaleString(DateTime.DATE_SHORT)
                : ""
            }
            disabled={true}
            className="py-3"
            placeholder="Select Date"
          />
          <Button
            variant="primary"
            loading={isProcessing}
            disabled={disabled}
            className="w-full mt-4"
            onClick={_handleUploadClick}>
            Upload
          </Button>
        </div>
        {error && (
          <ErrorMessage
            className="mt-4 mb-0"
            message={
              error.message || "Something went wrong Please try again later"
            }
          />
        )}
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default UploadRawData;
