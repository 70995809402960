import "ol/ol.css";
import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {parse as parseGeoTiff} from "geoblaze";
import {bboxPolygon as turfBboxPolygon} from "@turf/turf";
import type {Feature} from "geojson";

import Map from "ol/Map";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import BingMaps from "ol/source/BingMaps";
import TileLayer from "ol/layer/Tile";
import VectorSource from "ol/source/Vector";
import VectorImageLayer from "ol/layer/VectorImage";

import {useAppSelector} from "../../../util/hooks";
import {BING_MAPS_API_KEY} from "../../../config/secrets";

import {selectBlob} from "../../redux/uploadMosaicBlobSlice";

/* =============================================================================
<UploadMosaicMap />
============================================================================= */
const UploadMosaicMap: React.FC = () => {
  const map = useRef<Map>();
  const [uploadPolygon, setUploadPolygon] = useState<Feature | null>(null);

  const blob = useAppSelector(selectBlob, (a, b) => Boolean(a) === Boolean(b));

  // Get polygon from geotiff
  useEffect(() => {
    if (blob) {
      (async () => {
        try {
          const georaster = await parseGeoTiff(blob);

          const bbox = turfBboxPolygon([
            georaster.xmin,
            georaster.ymin,
            georaster.xmax,
            georaster.ymax,
          ]);

          setUploadPolygon(bbox);
        } catch (e) {
          toast(e.message);
        }
      })();
    }
  }, [blob]);

  // Initialize map
  useEffect(() => {
    // Add ol map
    map.current = new Map({
      target: "UploadMosaicMap",
      layers: [
        // Base layer
        new TileLayer({
          source: new BingMaps({
            key: BING_MAPS_API_KEY,
            imagerySet: "AerialWithLabelsOnDemand",
          }),
        }),
        // Upload points layer
        new VectorImageLayer({
          style: uploadPolygonStyle,
          source: new VectorSource(),
          imageRatio: 2,
        }),
      ],
      view: new View({
        center: [-98.5795, 39.8282],
        zoom: 16,
        projection: "EPSG:4326",
      }),
      controls: [],
    });
    return () => {
      map.current?.dispose();
    };
  }, []);

  // Set upload points layer
  useEffect(() => {
    // Get vector source
    const source = map.current
      ?.getAllLayers()[1]
      ?.getSource() as VectorSource | null;

    // Reset vector source
    source?.clear();

    if (uploadPolygon) {
      // Update vector source
      // @ts-expect-error GeoJSON().readFeature return type should not be an array
      source?.addFeature(new GeoJSON().readFeature(uploadPolygon));

      // Get source extent
      const extent = source?.getExtent();

      if (extent) {
        // Fit upload points on map
        map.current?.getView().fit(extent, {
          duration: 200,
          padding: [100, 100, 100, 100],
        });
      }
    }
  }, [uploadPolygon]);

  return <div id="UploadMosaicMap" className="w-full h-full z-0" />;
};

/**
 * Styles
 */

const uploadPolygonStyle = {
  "fill-color": "rgba(252, 85, 0, 0.4)",
  "stroke-color": "rgba(252, 85, 0, 0.6)",
  "stroke-width": 3,
};

/* Export
============================================================================= */
export default UploadMosaicMap;
