import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  type FormEvent,
} from "react";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";

import {Button, ErrorMessage, Select, TextInput} from "../../common";
import CloseIcon from "../../assets/icons/edit-close.svg";

import cropTypes from "../../static/cropTypes";
import sensorTypes from "../../static/sensorTypes";
import reportTypes from "../../static/reportTypes";

import {GET_FIELD} from "../api/queries";
import {wktToPolygon} from "../../util/functions";
import {generateMissionKmz} from "../../util/generateKmz";
import {useAppDispatch, useAppSelector} from "../../util/hooks";

import {actionClosed, selectSelectedField} from "../redux/farmSlice";

/* =============================================================================
<GenerateMissionFiles />
============================================================================= */
const GenerateMissionFiles: React.FC = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [sensorValue, setSensorValue] = useState("");
  const [scanTypesValue, setScanTypesValue] = useState("");
  const [reportTypesValue, setReportTypesValue] = useState("");
  const [cameraImageWidth, setCameraImageWidth] = useState("");
  const [cameraImageHeight, setCameraImageHeight] = useState("");
  const [cameraFocalLength, setCameraFocalLength] = useState("");
  const [cameraSensorWidth, setCameraSensorWidth] = useState("");
  const [cameraEnumValue, setCameraEnumValue] = useState("");
  const [cameraEnumSubValue, setCameraEnumSubValue] = useState("");
  const [cameraPayloadEnumValue, setCameraPayloadEnumValue] = useState("");

  const selectedField = useAppSelector(selectSelectedField, shallowEqual);
  const {data} = useQuery(GET_FIELD, {
    variables: {
      id: selectedField,
    },
  });

  const field = data?.field_season_shot_by_pk;

  const fieldReportTypes = useMemo(() => {
    const cropName = cropTypes.find(ct => ct.value === field?.cropName)?.value;

    if (cropName && reportTypes[cropName]) {
      return reportTypes[cropName];
    }

    const allFieldReportTypes: Array<{label: string; value: string}> = [];

    Object.keys(reportTypes).forEach(reportTypeName => {
      const rts = reportTypes[reportTypeName];

      rts.forEach(rt => {
        const isAdded = allFieldReportTypes.find(itm => itm.value === rt.value);

        if (!isAdded) {
          allFieldReportTypes.push(rt);
        }
      });
    });

    return allFieldReportTypes;
  }, [field]);

  const scanTypes = useMemo(() => {
    if (reportTypesValue) {
      const _reports = reportTypesValue
        .split(",")
        .map(report => fieldReportTypes.find(itm => itm.value === report));

      const _scanTypes: Array<{label: string; value: string}> = [];

      _reports.forEach(report => {
        report.scanTypes.forEach(st => {
          const isAdded = _scanTypes.find(itm => itm.value === st);

          if (!isAdded) {
            _scanTypes.push({
              label: st.toUpperCase(),
              value: st,
            });
          }
        });
      });

      return _scanTypes;
    }

    return [];
  }, [reportTypesValue, fieldReportTypes]);

  const disabled =
    loading ||
    !sensorValue ||
    !scanTypesValue ||
    !reportTypesValue ||
    !cameraFocalLength ||
    !cameraSensorWidth ||
    !cameraImageWidth ||
    !cameraImageHeight;

  // Close when there is no field
  useEffect(() => {
    if (!selectedField) {
      dispatch(actionClosed());
    }
  }, [selectedField, dispatch]);

  const _handleSensorSelect = (value: string) => {
    const sensorType = sensorTypes.find(st => st.value === value);

    if (sensorType) {
      setCameraFocalLength(`${sensorType.fr}`);
      setCameraSensorWidth(`${sensorType.sw}`);
      setCameraImageWidth(`${sensorType.imageWidth}`);
      setCameraImageHeight(`${sensorType.imageHeight}`);
      setCameraEnumValue(`${sensorType.enumValue}`);
      setCameraEnumSubValue(`${sensorType.enumSubValue}`);
      setCameraPayloadEnumValue(`${sensorType.payloadEnumValue}`);
    } else {
      setCameraFocalLength("");
      setCameraSensorWidth("");
      setCameraImageWidth("");
      setCameraImageHeight("");
      setCameraEnumValue("");
      setCameraEnumSubValue("");
      setCameraPayloadEnumValue("");
    }

    setSensorValue(value);
  };

  const _handleCloseClick = useCallback(() => {
    dispatch(actionClosed());
  }, [dispatch]);

  const _handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);

    try {
      const _sensorType = sensorTypes.find(
        itm => itm.value === sensorValue,
      ) as (typeof sensorTypes)[0];

      const _scanTypes = scanTypesValue.split(",");

      const _reportTypes = reportTypesValue
        .split(",")
        .map(rtValue => fieldReportTypes.find(rt => rt.value === rtValue));

      // Scan type validation
      _reportTypes.forEach(_reportType => {
        if (
          _reportType.scanTypes.length === 1 &&
          !_scanTypes.includes(_reportType.scanTypes[0])
        ) {
          throw new Error(
            `Please select ${_reportType.scanTypes[0].toUpperCase()} scan type for ${_reportType.label}.`,
          );
        }
      });

      const polygon = wktToPolygon(field?.polygon);
      const cropType = cropTypes.find(ct => ct.value === field?.cropName);
      const cropName = cropType ? cropType.label : field?.cropName;

      if (!polygon) {
        throw new Error("Invalid field polygon");
      }

      await Promise.all(
        _scanTypes.map(scanType => {
          const _reportTypesToScan = _reportTypes.filter(rt =>
            rt.scanTypes.includes(scanType),
          );

          // Find report with minimum GSD
          let minGsdReportType = _reportTypesToScan[0];

          _reportTypesToScan.forEach(_reportType => {
            if (
              _sensorType.type === "rgb" &&
              _reportType.gsdRgb < minGsdReportType.gsdRgb
            ) {
              minGsdReportType = _reportType;
            }

            if (
              _sensorType.type === "ms" &&
              _reportType.gsdMS < minGsdReportType.gsdMS
            ) {
              minGsdReportType = _reportType;
            }
          });

          const gsd = {
            ms: minGsdReportType.gsdMS,
            rgb: minGsdReportType.gsdRgb,
          };

          const sideLap = minGsdReportType.sideLap;

          const frontalLap = minGsdReportType.frontalLap;

          const _fileName = `${field?.name}_${cropName}_${_reportTypesToScan
            .map(rt => rt.value)
            .join("_")}_${scanType}`;

          return generateMissionKmz(
            +cameraSensorWidth,
            +cameraFocalLength,
            +cameraImageWidth,
            +cameraImageHeight,
            scanType,
            gsd[_sensorType.type],
            frontalLap,
            sideLap,
            polygon,
            _fileName,
            +cameraEnumValue,
            +cameraEnumSubValue,
            +cameraPayloadEnumValue,
          );
        }),
      );

      dispatch(actionClosed());
    } catch (e) {
      setError(e?.message);
    }

    setLoading(false);
  };

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white z-10 app-shadow">
      <button
        type="button"
        className="block ml-auto p-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <div className="w-[480px] px-9 pb-10 pt-2">
        <h1 className="text-xl font-medium text-center">
          Generate mission files
        </h1>
        <form
          className="w-full grid grid-cols-1 gap-y-5 mt-9"
          onSubmit={_handleSubmit}>
          <Select
            label="Select Sensor"
            value={sensorValue}
            options={sensorTypes}
            className="py-3.5"
            placeholder="Select here"
            onChange={_handleSensorSelect}
          />
          {sensorValue === "custom_camera" && (
            <div className="grid grid-cols-2 gap-3.5">
              <TextInput
                label="Focal (Real) Length (mm)"
                value={cameraFocalLength}
                className="py-3.5"
                placeholder="eg: 1.4"
                onChange={event => setCameraFocalLength(event.target.value)}
              />
              <TextInput
                label="Sensor Width (mm)"
                value={cameraSensorWidth}
                className="py-3.5"
                placeholder="eg: 1.4"
                onChange={event => setCameraSensorWidth(event.target.value)}
              />
              <TextInput
                label="Image Width (mm)"
                value={cameraImageWidth}
                className="py-3.5"
                placeholder="eg: 1.4"
                onChange={event => setCameraImageWidth(event.target.value)}
              />
              <TextInput
                label="Image Height (mm)"
                value={cameraImageHeight}
                className="py-3.5"
                placeholder="eg: 1.4"
                onChange={event => setCameraImageHeight(event.target.value)}
              />
            </div>
          )}
          <Select
            label="Select Report Types"
            value={reportTypesValue}
            isMulti={true}
            options={fieldReportTypes}
            className="py-3.5"
            placeholder="Select here"
            onChange={setReportTypesValue}
          />
          <Select
            label="Select Scan Type"
            isMulti={true}
            value={scanTypesValue}
            options={scanTypes}
            className="py-3.5"
            placeholder="Select here"
            onChange={setScanTypesValue}
          />
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            disabled={disabled}
            className="w-full mt-4">
            Generate
          </Button>
          {error && <ErrorMessage className="mb-0" message={error} />}
        </form>
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default GenerateMissionFiles;
