import React, {useCallback, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";

import UploadRawCancelDialog from "./UploadRawCancelDialog";
import AppIcon from "../../../assets/images/app-logo.svg";
import CloseIcon from "../../../assets/icons/edit-close.svg";

import {useAppDispatch, useAppSelector} from "../../../util/hooks";
import {GET_FARM_NAME} from "../../api/queries";

import {selectTotalBytes} from "../../redux/uploadRawFilesSlice";
import {
  rawUploadCancelled,
  selectFarm,
  selectStatus,
} from "../../redux/uploadSlice";

/* =============================================================================
<UploadRawHeader />
============================================================================= */
const UploadRawHeader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const farmId = useAppSelector(selectFarm, shallowEqual);
  const status = useAppSelector(selectStatus, shallowEqual);
  const totalBytes = useAppSelector(selectTotalBytes, shallowEqual);

  const {data} = useQuery(GET_FARM_NAME, {
    variables: {
      where: {
        id: {_eq: farmId},
      },
    },
  });

  const farm = data?.farms[0];

  const _handleCloseClick = useCallback(() => {
    // Upload finished
    if (status === "raw-finished") {
      dispatch(rawUploadCancelled());
      navigate("/", {
        replace: true,
      });
      return;
    }

    // Upload pending
    if (totalBytes > 0) {
      setCancelDialogOpen(true);
      return;
    }

    // Navigate to home in other cases
    navigate("/", {
      replace: true,
    });
  }, [status, totalBytes, dispatch, navigate]);

  const _handleCancelDialogClose = useCallback(() => {
    setCancelDialogOpen(false);
  }, []);

  return (
    <div className="w-full h-11 flex-none flex items-center bg-black">
      <Helmet>
        <title>Upload | Lense AI</title>
      </Helmet>
      <Link to="/" className="ml-6 mr-5">
        <AppIcon width={110} height={120} fill="#FFFFFF" />
      </Link>
      <h1 className="flex-1 ml-5 text-white">{farm ? farm.name : ""}</h1>
      <button type="button" className="ml-6 mr-4" onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <UploadRawCancelDialog
        isVisible={cancelDialogOpen}
        onClose={_handleCancelDialogClose}
      />
    </div>
  );
};

/* Export
============================================================================= */
export default UploadRawHeader;
