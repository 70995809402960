import React, {useEffect, useMemo, useRef, useState} from "react";
import wkt from "wkt";
import {toast} from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {shallowEqual} from "react-redux";
import {area as turfArea, lineDistance as turfLineDistance} from "@turf/turf";

import "ol/ol.css";
import Map from "ol/Map";
import WKT from "ol/format/WKT";
import View from "ol/View";
import Fill from "ol/style/Fill";
import Draw from "ol/interaction/Draw";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Overlay from "ol/Overlay";
import GeoJSON from "ol/format/GeoJSON";
import BingMaps from "ol/source/BingMaps";
import TileLayer from "ol/layer/Tile";
import LayerGroup from "ol/layer/Group";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoTIFFSource from "ol/source/GeoTIFF";
import WebGLTileLayer from "ol/layer/WebGLTile";
import WebGlPointsLayer from "ol/layer/WebGLPoints";
import {getArea, getLength} from "ol/sphere";
import {LineString, Polygon} from "ol/geom";
import type BaseEvent from "ol/events/Event";
import type {Geometry} from "ol/geom";
import type {DrawEvent} from "ol/interaction/Draw";
import {type Feature, type MapBrowserEvent} from "ol";
import { ZoomSlider } from 'ol/control';

import {WebGlLayer} from "../../common";

import ElevationLegend from "./ElevationLegend";

import {GET_FIELD} from "../../home/api/queries";
import {wktToPolygon} from "../../util/functions";
import {BING_MAPS_API_KEY} from "../../config/secrets";
import {downloader, request} from "../../util/download";
import {GET_FIELD_MAP_ANNOTATIONS} from "../api/queries";
import {useAppDispatch, useAppSelector} from "../../util/hooks";

import {
  geometryAdded,
  selectGeometry,
  selectTool,
  toolUnselected,
} from "../redux/fieldMapEditorSlice";
import {
  selectMap,
  selectLayer,
  selectLayerTitle,
  selectScanType,
  selectImageType,
} from "../redux/fieldTimelineSlice";
import {
  annotationSelected,
  selectAnnotationsVisible,
  selectSelectedAnnotations,
} from "../redux/fieldAnnotationsSlice";
import type BaseLayer from "ol/layer/Base";

/* =============================================================================
<FieldViewMap />
============================================================================= */
const FieldViewMap: React.FC = () => {
  const map = useRef<Map>();
  const cache = useRef<
    Record<string, {url: string; data: object; type: string}>
  >({});
  const annotationTooltipRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [annotationTooltipText, setAnnotationTooltipText] = useState("");

  const {fieldId} = useParams();

  const dispatch = useAppDispatch();
  const tool = useAppSelector(selectTool, shallowEqual);
  const mapId = useAppSelector(selectMap, shallowEqual);
  const layerId = useAppSelector(selectLayer, shallowEqual);
  const layerTitle = useAppSelector(selectLayerTitle, shallowEqual);
  const geometry = useAppSelector(selectGeometry, shallowEqual);
  const scanType = useAppSelector(selectScanType, shallowEqual);
  const imageType = useAppSelector(selectImageType, shallowEqual);
  const annotationsVisible = useAppSelector(
    selectAnnotationsVisible,
    shallowEqual,
  );
  const selectedAnnotations = useAppSelector(
    selectSelectedAnnotations,
    shallowEqual,
  );

  const {data: fieldData} = useQuery(GET_FIELD, {
    variables: {
      id: fieldId,
    },
  });

  const {data: fieldAnnotationsData} = useQuery(GET_FIELD_MAP_ANNOTATIONS, {
    variables: {
      where: {
        highResMapId: {
          _eq: mapId,
        },
      },
    },
  });

  const fieldPolygon = useMemo(() => {
    if (fieldData?.field_season_shot_by_pk) {
      return wktToPolygon(fieldData.field_season_shot_by_pk.polygon);
    }

    return null;
  }, [fieldData]);

  const fieldAnnotations = useMemo(() => {
    if (fieldAnnotationsData?.high_res_map_annotations.length) {
      const annotations: Array<Feature<Geometry>> = [];

      fieldAnnotationsData.high_res_map_annotations.forEach(annotation => {
        if (selectedAnnotations.includes(`${annotation.id}`)) {
          const feature = new WKT().readFeature(annotation.geometry, {
            dataProjection: "EPSG:4326",
          });

          let label = `${annotation.name}\n`;

          const geometryType = feature.getGeometry()?.getType();

          if (geometryType === "Polygon") {
            label += `${annotation.area} acres`;
          }
          if (geometryType === "LineString") {
            label += `${annotation.area} m`;
          }

          feature.setProperties({
            id: `${annotation.id}`,
            type: "annotation",
            name: annotation.name,
            area: annotation.area,
            label,
            shape: geometryType,
            description: annotation.description,
          });

          annotations.push(feature);
        }
      });

      return annotations;
    }

    return [];
  }, [fieldAnnotationsData, selectedAnnotations]);

  // Initialize
  useEffect(() => {
    // Add ol map
    map.current = new Map({
      target: "FieldViewMap",
      layers: [
        // Base layer
        new TileLayer({
          source: new BingMaps({
            key: BING_MAPS_API_KEY,
            imagerySet: "AerialWithLabelsOnDemand",
            interpolate: false,
          }),
        }),
        // Polygon layer
        new VectorLayer({}),
        // High res layer
        new WebGLTileLayer({}),
        // High res rapid layer group
        new LayerGroup({}),
        // Analysis layer group
        new LayerGroup({}),
        // Annotations layer
        new VectorLayer({}),
        // Draw annotations layer
        new VectorLayer({}),
      ],
      view: new View({
        center: [0, 0],
        zoom: 2,
        projection: "EPSG:4326",
        maxZoom: 34,
      }),
      controls: [new ZoomSlider()]
    });

    const _handleMapClick = (event: MapBrowserEvent<never>) => {
      const feature = map.current?.forEachFeatureAtPixel(
        event.pixel,
        ftr => ftr,
      );

      const featureProps = feature?.getProperties();

      if (featureProps?.type === "annotation") {
        dispatch(annotationSelected(featureProps.id));
      }
    };

    map.current.on("click", _handleMapClick);

    return () => {
      // Remove event listeners
      map.current?.removeEventListener("click", _handleMapClick);

      // Clean up
      map.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Exclude dispatch

  // Get field polygon layer
  useEffect(() => {
    // Remove vector layer
    map.current?.getLayers().item(1).setVisible(false);

    if (fieldPolygon) {
      // Create vector source
      const source = new VectorSource({
        features: new GeoJSON().readFeatures(fieldPolygon),
      });

      // Add vector layer
      map.current?.getLayers().setAt(
        1,
        new VectorLayer({
          source,
          style: fieldPolygonStyle,
        }),
      );

      // Get source extent
      const extent = source?.getExtent();

      if (extent) {
        // Fit field polygon on map
        map.current?.getView().fit(extent, {
          padding: [100, 100, 100, 100],
        });
      }

      // Create new vector layer as overlay (boundary)
      const fieldBoundaryLayer = new VectorLayer({
        map: map.current,
        source: new VectorSource({
          features: new GeoJSON().readFeatures(fieldPolygon),
        }),
        style: fieldBoundaryStyle,
      });

      return () => {
        // Remove boundary layer
        fieldBoundaryLayer.dispose();
      };
    }
  }, [fieldPolygon]);

  // Set high res map layers on map change
  useEffect(() => {
    const layers = map.current?.getLayers();

    // Add polygon layer
    layers?.item(1).setVisible(true);

    // Remove high res map layers
    layers?.item(2).setVisible(false);
    layers?.item(3).setVisible(false);

    // Remove analysis layer
    layers?.item(4).setVisible(false);

    if (mapId) {
      // Update high res map layers
      _setHighResMapLayers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapId]);

  // Update high res map layer on image type change
  useEffect(() => {
    const layers = map.current?.getLayers();

    if (mapId && layers?.item(2).getVisible()) {
      // Remove high res map layers
      layers?.item(2).setVisible(false);
      layers?.item(3).setVisible(false);

      // Update high res map layers
      _setHighResMapLayers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageType]);

  // Show / Hide high res map rapid layer on scan type change
  useEffect(() => {
    // Get rapid layer
    const layer = map.current?.getLayers().item(3);

    if (mapId && scanType === "rapid") {
      layer?.setVisible(true);
    } else {
      layer?.setVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanType]);

  // Get analysis layer
  useEffect(() => {
    const layers = map.current?.getLayers();

    // Remove analysis layer
    layers?.item(4).setVisible(false);

    if (mapId && layerId) {
      setAnalysisLayers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerId]);

  // Get field annotations layer
  useEffect(() => {
    // Remove annotations layer
    map.current?.getLayers().item(5).setVisible(false);

    if (fieldAnnotations.length) {
      // Create vector source
      const source = new VectorSource({
        features: fieldAnnotations,
      });

      // Add vector layer
      map.current?.getLayers().setAt(
        5,
        new VectorLayer({
          style: fieldAnnotationsStyle,
          source,
          visible: annotationsVisible,
        }),
      );
    }
  }, [fieldAnnotations, annotationsVisible]);

  // Add map interaction according to tool selected
  useEffect(() => {
    if (tool) {
      // Create vector source
      const source = new VectorSource();

      // Add draw annotations layer
      map.current?.getLayers().setAt(
        6,
        new VectorLayer({
          style: fieldAnnotationsStyle,
          source,
        }),
      );

      // Create draw interaction
      const draw = new Draw({
        source,
        type: tool,
        condition: event => {
          // Remove last point on right click
          if (
            event.originalEvent.pointerType === "mouse" &&
            event.originalEvent.button === 2
          ) {
            draw.removeLastPoint();
            return false;
          }

          return true;
        },
      });

      // Create draw annotation tooltip overlay
      const tooltipOverlay = new Overlay({
        element: annotationTooltipRef.current ?? undefined,
        offset: [0, -15],
        positioning: "bottom-center",
        stopEvent: false,
        insertFirst: false,
      });

      // Reference of currently drawn geometry
      let drawGeometry: Geometry | undefined;

      const _handleDrawGeometryChange = (event: BaseEvent) => {
        const geom = event.target;

        if (geom instanceof Polygon) {
          setAnnotationTooltipText(formatArea(geom));

          tooltipOverlay?.setPosition(geom.getInteriorPoint().getCoordinates());
        }

        if (geom instanceof LineString) {
          setAnnotationTooltipText(formatLength(geom));

          tooltipOverlay?.setPosition(geom.getLastCoordinate());
        }
      };

      const _handleDrawStart = (event: DrawEvent) => {
        // Add tooltip overlay
        map.current?.addOverlay(tooltipOverlay);

        // Get feature geometry
        drawGeometry = event.feature.getGeometry();

        // Add change event listener on draw geometry
        drawGeometry?.on("change", _handleDrawGeometryChange);
      };

      const _handleDrawAbort = () => {
        // Remove tooltip overlay
        map.current?.removeOverlay(tooltipOverlay);
      };

      const _handleDrawEnd = (event: DrawEvent) => {
        // Remove tooltip overlay
        map.current?.removeOverlay(tooltipOverlay);

        // Get feature geometry
        const finalDrawGeometry = event.feature.getGeometry();

        if (finalDrawGeometry) {
          const format = new GeoJSON();
          const parsedGeometry = format.writeGeometryObject(finalDrawGeometry);

          let area = 0;

          if (parsedGeometry.type === "Polygon") {
            area = +(turfArea(parsedGeometry) / 4046.8564224).toFixed(2);
          }

          if (parsedGeometry.type === "LineString") {
            // @ts-expect-error parsed geometry type
            area = +turfLineDistance(parsedGeometry, {
              units: "meters",
            }).toFixed(2);
          }

          let label = "";

          const geometryType = event.feature.getGeometry()?.getType();

          if (geometryType === "Polygon") {
            label = `${area} acres`;
          }
          if (geometryType === "LineString") {
            label = `${area} m`;
          }

          event.feature.setProperties({
            type: "new-annotation",
            area,
            label,
            shape: geometryType,
          });

          dispatch(geometryAdded(wkt.stringify(parsedGeometry)));
        }

        // Unselect tool
        dispatch(toolUnselected());
      };

      // Add draw interaction
      draw.on("drawstart", _handleDrawStart);
      draw.on("drawabort", _handleDrawAbort);
      draw.on("drawend", _handleDrawEnd);
      map.current?.addInteraction(draw);

      // Remove interaction on "ESC" press
      const _handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          // Abort drawing
          draw.abortDrawing();
        }
        if (event.key === "Delete") {
          // Abort drawing
          draw.abortDrawing();

          // Unselect tool
          dispatch(toolUnselected());
        }
      };
      window.addEventListener("keyup", _handleKeyUp);

      return () => {
        // Remove listeners
        drawGeometry?.removeEventListener("change", _handleDrawGeometryChange);
        draw.removeEventListener("drawstart", _handleDrawStart);
        draw.removeEventListener("drawabort", _handleDrawAbort);
        draw.removeEventListener("drawend", _handleDrawEnd);
        window.removeEventListener("keyup", _handleKeyUp);

        // Remove interaction
        map.current?.removeInteraction(draw);
      };
    }

    // Clear draw annotations layer when there is no tool selected nor there is a geometry
    if (!geometry) {
      // Get draw annotations layer
      const layer = map.current?.getLayers().item(6) as VectorLayer;

      // Remove features from draw annotations
      layer?.getSource()?.clear();
    }
  }, [tool, geometry, dispatch]);

  const _getHighResMapData = async () => {
    const cachePayload = cache.current[`map/${mapId}`];

    if (cachePayload) {
      return cachePayload;
    }

    // Get presigned url
    const payload = await request({
      url: `/high_res_maps/${mapId}`,
      method: "GET",
    });

    // Update cache
    cache.current[`map/${mapId}`] = payload;

    return payload;
  };

  const _getAnalysisLayerData = async () => {
    const cachePayload = cache.current[`map/${mapId}/layer/${layerId}`];

    if (cachePayload) {
      return cachePayload;
    }

    // Get presigned url
    const payload = await request({
      url: `/high_res_maps/${mapId}/high_res_map_layers/${layerId}`,
      method: "GET",
    });

    // Update cache
    cache.current[`map/${mapId}/layer/${layerId}`] = payload;

    return payload;
  };

  const _setHighResMapLayers = async () => {
    setIsLoading(true);

    try {
      // Get presigned url
      const payload = await _getHighResMapData();

      // Get url according to image type.
      const url = payload[`${imageType}_url`] || payload.url;

      // Create geotiff source
      const source = new GeoTIFFSource({
        sources: [
          {
            url: url,
          },
        ],
        interpolate: false,
      });

      // Add high res map layer
      map.current?.getLayers().setAt(
        2,
        new WebGLTileLayer({
          source,
        }),
      );

      // Hide polygon layer from the map
      map.current?.getLayers().item(1).setVisible(false);

      // Get rapid url according to image type.
      const rapidUrl = payload[`${imageType}_rapid_url`];

      if (rapidUrl) {
        const rapidSources: GeoTIFFSource[] = [];

        if (Array.isArray(rapidUrl)) {
          // Grouped geotiff sources
          rapidUrl.forEach(itm => {
            const rapidSource = new GeoTIFFSource({
              sources: [
                {
                  url: itm,
                },
              ],
              interpolate: false,
            });

            rapidSources.push(rapidSource);
          });
        } else {
          // Single geotiff source
          const rapidSource = new GeoTIFFSource({
            sources: [
              {
                url: rapidUrl,
              },
            ],
            interpolate: false,
          });

          rapidSources.push(rapidSource);
        }

        // Add high res map rapid layers
        map.current?.getLayers().setAt(
          3,
          new LayerGroup({
            layers: rapidSources.map(source => new WebGLTileLayer({source})),
            visible: scanType === "rapid",
          }),
        );
      }
    } catch (e) {
      toast(e.message);
    }

    setIsLoading(false);
  };

  const setAnalysisLayers = async () => {
    setIsLoading(true);

    try {
      const payload = await _getAnalysisLayerData();

      const layers: BaseLayer[] = [];

      for (const layer of payload.layers) {
        // Geotiff layer
        if (layer.type === "tif" || layer.type === "tiff") {
          // Create geotiff source
          const source = new GeoTIFFSource({
            sources: [
              {
                url: layer.url,
              },
            ],
            interpolate: false,
          });

          // Add raster analysis layer
          layers.push(new WebGLTileLayer({source}));
        }

        // Geojson layer
        if (layer.type === "json" || layer.type === "geojson") {
          // Get geojson features
          const geojsonResponse = await downloader({
            url: layer.url,
          });

          // Format geojson payload
          const geojsonPayload = {
            ...geojsonResponse.data,
            features: geojsonResponse.data.features.map(feature => ({
              ...feature,
              properties: {
                ...feature.properties,
                color: feature.properties.color ?? "",
                class: feature.properties.class ?? "",
              },
            })),
          };

          // Create vector source
          const source = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonPayload),
          });

          if (geojsonPayload.features[0].geometry.type === "Point") {
            // Add vector points analysis layer
            layers.push(
              new WebGlPointsLayer({
                style: geojsonPointsStyle,
                source,
              }),
            );
          } else {
            // Add vector analysis layer
            layers.push(new WebGlLayer({source}));
          }
        }
      }

      // Add analysis layer group
      map.current?.getLayers().setAt(
        4,
        new LayerGroup({
          layers: layers,
        }),
      );
    } catch (e) {
      toast(e.message);
    }

    setIsLoading(false);
  };

  return (
    <div className="h-full relative flex-1">
      {layerTitle === "Elevation" && (
        <div className="absolute bottom-5 right-5 z-10">
          <ElevationLegend minElevation={0} maxElevation={15} />
        </div>
      )}
      <div id="FieldViewMap" className="w-full h-full z-0" />
      <div
        ref={annotationTooltipRef}
        className={`${annotationTooltipText ? "block" : "hidden"} p-2 bg-[rgba(0,0,0,0.5)] rounded-md text-xs text-white`}>
        {annotationTooltipText}
      </div>
      {isLoading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[rgba(0,0,0,0.2)]">
          <ClipLoader color="#FFFFFF" />
        </div>
      )}
    </div>
  );
};

/**
 * Styles
 */

const fieldPolygonStyle = new Style({
  fill: new Fill({
    color: "rgba(252, 85, 0, 0.4)",
  }),
});

const fieldBoundaryStyle = new Style({
  stroke: new Stroke({
    color: "rgba(252, 85, 0, 1)",
    width: 3,
  }),
});

const geojsonPointsStyle = {
  "circle-radius": 10,
  "circle-fill-color": [
    "case",
    ["==", ["get", "class"], 0],
    "#E3412B",
    ["==", ["get", "class"], 1],
    "#FEB743",
    ["==", ["get", "class"], 2],
    "#30C876",
    ["==", ["get", "class"], 3],
    "#1E8C4D",
    ["==", ["get", "class"], "saline_soil"],
    "rgba(171, 23, 23, 1)",
    ["==", ["get", "class"], "waterlog"],
    "rgba(129, 214, 238, 1)",
    "rgba(90, 202, 91, 1)",
  ],
  "circle-displacement": [0, 0],
  "circle-opacity": 1,
};

const fieldAnnotationsStyle = {
  "fill-color": "rgba(252, 85, 0, 0.4)",
  "stroke-color": "rgba(252, 85, 0, 1)",
  "stroke-width": 3,
  "circle-fill-color": "rgba(252, 85, 0, 0.4)",
  "circle-stroke-color": "rgba(252, 85, 0, 1)",
  "circle-stroke-width": 3,
  "circle-radius": 8,
  "text-font": "500 12px Poppins,sans-serif",
  "text-value": ["string", ["get", "label"], ""],
  "text-overflow": true,
  "text-fill-color": "#FFFFFF",
  "text-offset-y": [
    "case",
    ["==", ["get", "shape"], "LineString"],
    24,
    ["==", ["get", "shape"], "Point"],
    28,
    0,
  ],
};

/**
 * Helper functions
 */

const formatArea = function (polygon) {
  const area = getArea(polygon, {
    projection: "EPSG:4326",
  });

  return `${((Math.round(area * 100) / 100) * 0.000247105).toFixed(2)} acres`;
};

const formatLength = function (line) {
  const length = getLength(line, {
    projection: "EPSG:4326",
  });

  return `${length.toFixed(2)} m`;
};

/* Export
============================================================================= */
export default FieldViewMap;
