import React, {useCallback, useEffect, useState} from "react";
import {shallowEqual} from "react-redux";
import {directoryOpen, fileOpen} from "browser-fs-access";

import {Button, ErrorMessage} from "../../../common";
import UploadRawData from "./UploadRawData";
import UploadMosaicData from "./UploadMosaicData";
import CloseIcon from "../../../assets/icons/edit-close.svg";

import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {actionClosed} from "../../redux/farmSlice";
import {selectTotalBytes as selectTotalRawBytes} from "../../../upload/redux/uploadRawFilesSlice";
import {selectTotalBytes as selectTotalMosaicBytes} from "../../../upload/redux/uploadMosaicPartsSlice";
import {
  selectRawUpload,
  selectMosaicData,
  rawUploadCancelled,
  mosaicUploadCancelled,
  selectError,
  selectStatus,
} from "../../../upload/redux/uploadSlice";

/* =============================================================================
<UploadData />
============================================================================= */
const UploadData: React.FC = () => {
  const dispatch = useAppDispatch();
  const [dataType, setDataType] = useState<"raw" | "mosaic" | null>(null);

  const error = useAppSelector(selectError, shallowEqual);
  const status = useAppSelector(selectStatus, shallowEqual);
  const totalRawBytes = useAppSelector(
    selectTotalRawBytes,
    (a, b) => Boolean(a) === Boolean(b),
  );
  const totalMosaicBytes = useAppSelector(
    selectTotalMosaicBytes,
    (a, b) => Boolean(a) === Boolean(b),
  );

  const isRawProcessing = status === "raw-processing";
  const isMosaicProcessing = status === "mosaic-processing";

  // Initialize data type
  useEffect(() => {
    if (totalRawBytes > 0) {
      setDataType("raw");
      return;
    }

    if (totalMosaicBytes > 0) {
      setDataType("mosaic");
      return;
    }
  }, [totalRawBytes, totalMosaicBytes]);

  const _handleCloseClick = useCallback(() => {
    dispatch(actionClosed());
    dispatch(rawUploadCancelled());
    dispatch(mosaicUploadCancelled());
  }, [dispatch]);

  const _handleRawDataClick = useCallback(async () => {
    try {
      const blobsInDirectory = (await directoryOpen({
        id: `raw-data`,
        mode: "read",
        recursive: true,
        skipDirectory: entry => entry.name[0] === ".",
      })) as File[];

      const result = await dispatch(selectRawUpload(blobsInDirectory));

      if (selectRawUpload.fulfilled.match(result)) {
        setDataType("raw");
      }
    } catch (e) {
      // TODO
    }
  }, [dispatch]);

  const _handleOrthomosaicClick = useCallback(async () => {
    try {
      const blob = (await fileOpen({
        id: "orthomosaic",
        mimeTypes: ["image/tiff"],
      })) as File;

      const result = await dispatch(selectMosaicData(blob));

      if (selectMosaicData.fulfilled.match(result)) {
        setDataType("mosaic");
      }
    } catch (e) {
      // TODO
    }
  }, [dispatch]);

  const _handleRawDataClose = useCallback(() => {
    setDataType(null);
    dispatch(rawUploadCancelled());
  }, [dispatch]);

  const _handleOrthomosaicClose = useCallback(() => {
    setDataType(null);
    dispatch(mosaicUploadCancelled());
  }, [dispatch]);

  // Upload raw data
  if (dataType === "raw") {
    return (
      <UploadRawData
        onClose={_handleRawDataClose}
        onSelectFolder={_handleRawDataClick}
      />
    );
  }

  // Upload mosaic data
  if (dataType === "mosaic") {
    return (
      <UploadMosaicData
        onClose={_handleOrthomosaicClose}
        onSelectFile={_handleOrthomosaicClick}
      />
    );
  }

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white z-10 app-shadow">
      <button
        type="button"
        className="block ml-auto p-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <div className="w-[360px] px-9 pb-10 pt-2">
        <h1 className="mx-9 text-xl font-medium text-center">
          Upload field data
        </h1>
        <Button
          size="lg"
          variant="primary"
          loading={isRawProcessing}
          disabled={isRawProcessing}
          className="w-full mt-12"
          onClick={_handleRawDataClick}>
          Raw Data
        </Button>
        <Button
          size="lg"
          variant="secondary"
          loading={isMosaicProcessing}
          disabled={isMosaicProcessing}
          className="w-full mt-5"
          onClick={_handleOrthomosaicClick}>
          Orthomosaic
        </Button>
        <p className="font-medium text-sm text-zinc-500 mt-12 mb-1">
          By uploading raw data, our software will automatically generate an
          orthomosaic.
        </p>
        {error && (
          <ErrorMessage
            className="mt-9 mb-1"
            message={
              error.message || "Something went wrong Please try again later"
            }
          />
        )}
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default UploadData;
