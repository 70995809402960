import React, {useCallback} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {shallowEqual} from "react-redux";

import CompletedIcon from "../../../../assets/icons/edit-completed.svg";

import {useAppDispatch, useAppSelector} from "../../../../util/hooks";

import {selectStatus} from "../../../redux/uploadSlice";
import {
  fileSelected,
  fileUnselected,
  selectUploadFileById,
} from "../../../redux/uploadRawFilesSlice";

interface Props {
  id: string;
  style: React.CSSProperties;
}

/* =============================================================================
<UploadRawFilesItem />
============================================================================= */
const UploadRawFilesItem: React.FC<Props> = ({id, style}) => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectStatus, shallowEqual);
  const file = useAppSelector(
    state => selectUploadFileById(state, id),
    shallowEqual,
  );

  const name = file.name;
  const selected = file.selected;
  const uploaded = file.status === "uploaded";
  const uploading = status === "raw-uploading" && file.status === "uploading";

  const _handleChange = useCallback(() => {
    if (selected) {
      dispatch(fileUnselected(id));
    } else {
      dispatch(fileSelected(id));
    }
  }, [id, selected, dispatch]);

  return (
    <div className="flex items-center px-4" style={style}>
      <input
        id={`${id}-checkbox`}
        type="checkbox"
        checked={selected}
        className="w-4 h-4 accent-black text-black bg-gray-100 border-gray-300 cursor-pointer"
        onChange={_handleChange}
      />
      <label
        htmlFor={`${id}-checkbox`}
        style={{color: selected ? "#000000" : "#696969"}}
        className="flex-1 ml-3 text-sm font-medium truncate cursor-pointer">
        {name}
      </label>
      <div className="ml-3">
        {uploaded && <CompletedIcon />}
        {uploading && (
          <ClipLoader
            color="#FF7E1D"
            loading={true}
            size={17}
            aria-label="Processing Spinner"
            data-testid="loader"
          />
        )}
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default UploadRawFilesItem;
