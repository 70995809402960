import type React from "react";
import {useCallback, useEffect, useRef} from "react";
import {shallowEqual} from "react-redux";
import {isCancel} from "axios";

import {request, uploader} from "../../../util/upload";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {selectBlobsByIds} from "../../redux/uploadRawBlobsSlice";
import {
  finishRawUpload,
  rawUploadFailed,
  rawUploadAborted,
  selectDate as selectUploadDate,
  selectStatus as selectUploadStatus,
} from "../../redux/uploadSlice";
import {
  filesUploaded,
  filesUploadStarted,
  selectTotalBytes,
  selectUploadedBytes,
  selectUploadingFiles,
  selectFilesToUploadIds,
} from "../../redux/uploadRawFilesSlice";

/* =============================================================================
<UploadRawObserver />
============================================================================= */
const UploadRawObserver: React.FC = () => {
  const dispatch = useAppDispatch();
  const abortController = useRef<AbortController | null>(null);

  const uploadDate = useAppSelector(selectUploadDate, shallowEqual);
  const uploadStatus = useAppSelector(selectUploadStatus, shallowEqual);
  const totalBytes = useAppSelector(selectTotalBytes, shallowEqual);
  const uploadedBytes = useAppSelector(selectUploadedBytes, shallowEqual);
  const filesToUpload = useAppSelector(
    state => selectFilesToUploadIds(state, 10),
    shallowEqual,
  );
  const uploadingFiles = useAppSelector(selectUploadingFiles, (a, b) => {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      const prevFile = a[i];
      const nextFile = b[i];

      if (prevFile.id !== nextFile.id) {
        return false;
      }
      if (prevFile.name !== nextFile.name) {
        return false;
      }
      if (prevFile.path !== nextFile.path) {
        return false;
      }
      if (prevFile.size !== nextFile.size) {
        return false;
      }
      if (prevFile.status !== nextFile.status) {
        return false;
      }
      if (prevFile.fields.toString() !== nextFile.fields.toString()) {
        return false;
      }
      if (prevFile.metadata?.latitude !== nextFile.metadata?.latitude) {
        return false;
      }
      if (prevFile.metadata?.longitude !== nextFile.metadata?.longitude) {
        return false;
      }
    }

    return true;
  });
  const uploadingBlobs = useAppSelector(
    state =>
      selectBlobsByIds(
        state,
        uploadingFiles.map(file => file.id),
      ),
    shallowEqual,
  );

  // Prevent window close by user when uploading
  useEffect(() => {
    if (uploadingBlobs?.length) {
      window.addEventListener("beforeunload", _handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", _handleBeforeUnload);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingBlobs]);

  // Resume upload on mount - web app closed during previous upload
  useEffect(() => {
    if (uploadingFiles.length > 0 && !uploadingBlobs?.length) {
      dispatch(rawUploadAborted());
    }
  }, [uploadingFiles, uploadingBlobs, dispatch]);

  // Select next uploading file
  useEffect(() => {
    if (filesToUpload.length > 0 && uploadStatus === "raw-uploading") {
      dispatch(filesUploadStarted(filesToUpload));
    }
  }, [filesToUpload, uploadStatus, dispatch]);

  // Upload file to presigned URL
  useEffect(() => {
    if (
      uploadStatus === "raw-uploading" &&
      uploadingFiles.length > 0 &&
      uploadingBlobs?.length
    ) {
      (async () => {
        try {
          const fields: string[] = [];

          uploadingFiles.forEach(file => {
            file.fields.forEach(field => {
              if (!fields.includes(field)) {
                fields.push(field);
              }
            });
          });

          // Get presigned URLs
          const response: Record<string, string>[] = await Promise.all(
            fields.map(field => {
              const files = uploadingFiles
                .filter(file => file.fields.includes(field))
                .map(file => file.name);

              return request({
                url: "/raw",
                method: "PUT",
                data: {
                  date: uploadDate,
                  field,
                  files,
                },
              });
            }),
          );

          // Abort controller ref
          abortController.current = new AbortController();

          const uploadPromises: Promise<unknown>[] = [];

          response.forEach(urls => {
            for (const key in urls) {
              const blob = uploadingBlobs.find(blob => blob.name === key);

              if (blob) {
                const uploadPromise = uploader({
                  url: urls[key],
                  data: blob,
                  signal: abortController.current?.signal,
                });

                uploadPromises.push(uploadPromise);
              }
            }
          });

          // Upload blobs in parallel
          await Promise.all(uploadPromises);

          // Remove abort controller ref
          abortController.current = null;

          // Mark file as uploaded
          dispatch(filesUploaded(uploadingFiles.map(file => file.id)));
        } catch (e) {
          // Remove abort controller ref
          abortController.current = null;

          // Upload failed when not cancelled
          if (!isCancel(e)) {
            dispatch(rawUploadFailed());
          }
        }
      })();

      return () => {
        if (abortController.current) {
          abortController.current.abort();
        }
      };
    }
  }, [uploadDate, uploadStatus, uploadingFiles, uploadingBlobs, dispatch]);

  // Cancel ongoing request on status change
  useEffect(() => {
    if (abortController.current && uploadStatus !== "raw-uploading") {
      abortController.current.abort();
    }
  }, [uploadStatus, abortController]);

  // Upload finished
  useEffect(() => {
    if (totalBytes > 0 && uploadedBytes === totalBytes) {
      dispatch(finishRawUpload());
    }
  }, [totalBytes, uploadedBytes, dispatch]);

  const _handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to abort the upload?");
  }, []);

  return null;
};

/* Export
============================================================================= */
export default UploadRawObserver;
