import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";
import {rawUploadCancelled} from "./uploadSlice";

interface UploadRawBlobsState {
  blobs: {
    [index: string]: File;
  };
}

// Initial state

const initialState: UploadRawBlobsState = {
  blobs: {},
};

// Slice
const uploadRawBlobsSlice = createSlice({
  name: "uploadRawBlobs",
  initialState,
  reducers: {
    blobsAdded(state, action: PayloadAction<UploadRawBlobsState["blobs"]>) {
      state.blobs = action.payload;
    },
    blobsUpdated(state, action: PayloadAction<UploadRawBlobsState["blobs"]>) {
      state.blobs = {
        ...state.blobs,
        ...action.payload,
      };
    },
    blobsRemoved(state, action: PayloadAction<string[]>) {
      action.payload.forEach(file => {
        delete state.blobs[file];
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(rawUploadCancelled, state => {
      state.blobs = {};
    });
  },
});

// Actions
export const {blobsAdded, blobsUpdated, blobsRemoved} =
  uploadRawBlobsSlice.actions;

// Reducer
export default uploadRawBlobsSlice.reducer;

// Selectors

export const selectBlobs = (state: RootState) =>
  state.uploadRawBlobs?.blobs ?? {};

export const selectBlobsByIds = (state: RootState, ids: string[]) => {
  const blobs = selectBlobs(state);

  const files: File[] = [];

  for (const id in blobs) {
    if (ids.includes(id)) {
      files.push(blobs[id]);
    }
  }

  return files;
};
