import React, { useEffect, useRef } from 'react';

interface ElevationLegendProps {
  minElevation: number;
  maxElevation: number;
}

const ElevationLegend: React.FC<ElevationLegendProps> = ({ minElevation, maxElevation }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // Define color stops within the component
  const colorStops = [
    { elevation: 0.0, rgb: [0, 0, 0] },
    { elevation: 1.44, rgb: [135, 0, 152] },
    { elevation: 2.88, rgb: [0, 0, 221] },
    { elevation: 4.32, rgb: [0, 151, 221] },
    { elevation: 5.76, rgb: [0, 170, 135] },
    { elevation: 7.20, rgb: [0, 188, 0] },
    { elevation: 8.64, rgb: [0, 255, 0] },
    { elevation: 10.08, rgb: [238, 236, 0] },
    { elevation: 11.52, rgb: [255, 153, 0] },
    { elevation: 12.96, rgb: [220, 0, 0] },
    { elevation: 14.40, rgb: [204, 204, 204] },
  ];

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const width = canvas.width;
        const height = canvas.height;

        // Create a gradient for the colormap
        const gradient = ctx.createLinearGradient(0, 0, width, 0);

        // Calculate the range of elevations
        const range = maxElevation - minElevation;

        // Add color stops dynamically
        colorStops.forEach(({ elevation, rgb }) => {
          const normalizedPosition = (elevation - minElevation) / range;
          const color = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
          gradient.addColorStop(normalizedPosition, color);
        });

        // Apply the gradient to the canvas
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, width, height);
      }
    }
  }, [minElevation, maxElevation, colorStops]);

  return (
    <div style={styles.legendContainer}>
      <div style={styles.legendTitle}>Elevation Legend</div>
      <canvas ref={canvasRef} width={180} height={20} />
      <div style={styles.legendRange}>
        <span>{minElevation} m</span> - <span>{maxElevation} m</span>
      </div>
    </div>
  );
};

// Inline styles for the legend
const styles = {
  legendContainer: {
    marginTop: '10px',
    border: '1px solid #ddd',
    padding: '5px',
    width: '192px',
    backgroundColor: 'white',
  },
  legendTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
  },
  legendRange: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  colorBox: {
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
};

export default ElevationLegend;
