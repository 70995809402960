import React from "react";
import {shallowEqual} from "react-redux";
import {
  FixedSizeList as List,
  type ListItemKeySelector,
  type ListChildComponentProps,
} from "react-window";

import UploadRawFilesHeader from "./UploadRawFilesHeader";
import UploadRawFilesItem from "./UploadRawFilesItem";

import {useAppSelector, useWindowDimensions} from "../../../../util/hooks";

import {
  selectUploadFilesIds,
  selectUploadFilesTotal,
} from "../../../redux/uploadRawFilesSlice";

/* =============================================================================
<UploadRawFiles />
============================================================================= */
const UploadRawFiles: React.FC = () => {
  const files = useAppSelector(selectUploadFilesIds, shallowEqual);
  const totalFiles = useAppSelector(selectUploadFilesTotal, shallowEqual);
  const {height: windowHeight} = useWindowDimensions();

  return (
    <div className="h-full w-[366px] app-shadow">
      <UploadRawFilesHeader />
      <List
        width={366}
        height={windowHeight * 0.88}
        className="mt-2"
        itemData={files}
        itemCount={totalFiles}
        itemSize={32}
        itemKey={keyExtractor}>
        {renderRow}
      </List>
    </div>
  );
};

const keyExtractor: ListItemKeySelector<string[]> = (index, data) =>
  data[index];

const renderRow: React.FC<ListChildComponentProps<string[]>> = ({
  data,
  index,
  style,
}) => {
  const id = data[index];

  return <UploadRawFilesItem id={id} style={style} />;
};

/* Export
============================================================================= */
export default UploadRawFiles;
