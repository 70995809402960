import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";

// Initial state

interface FieldTimelineState {
  map: string | null;
  layer: string | null;
  layerTitle: string | null;
  scanType: "full" | "rapid" | null;
  imageType: "ms" | "rgb" | null;
}

const initialState: FieldTimelineState = {
  map: null,
  layer: null,
  layerTitle: null,
  scanType: "full",
  imageType: "rgb",
};

// Slice
const fieldTimelineSlice = createSlice({
  name: "fieldTimeline",
  initialState,
  reducers: {
    mapSelected(state, action: PayloadAction<string>) {
      state.map = action.payload;
      state.layer = null;
    },
    mapUnselected(state) {
      state.map = null;
      state.layer = null;
    },
    layerTitleSelected(state, action: PayloadAction<string>) {
      state.layerTitle = action.payload;
    },
    layerSelected(state, action: PayloadAction<string>) {
      state.layer = action.payload;
    },
    layerUnselected(state) {
      state.layer = null;
    },
    scanTypeSelected(state, action: PayloadAction<"full" | "rapid">) {
      state.scanType = action.payload;
    },
    imageTypeSelected(state, action: PayloadAction<"ms" | "rgb">) {
      state.imageType = action.payload;
    },
  },
});

// Actions
export const {
  mapSelected,
  mapUnselected,
  layerTitleSelected,
  layerSelected,
  layerUnselected,
  scanTypeSelected,
  imageTypeSelected,
} = fieldTimelineSlice.actions;

// Reducer
export default fieldTimelineSlice.reducer;

// Selectors

export const selectMap = (state: RootState) => state.fieldTimeline?.map ?? null;

export const selectLayer = (state: RootState) =>
  state.fieldTimeline?.layer ?? null;

export const selectLayerTitle = (state: RootState) =>
  state.fieldTimeline?.layerTitle ?? null;

export const selectScanType = (state: RootState) =>
  state.fieldTimeline?.scanType ?? null;

export const selectImageType = (state: RootState) =>
  state.fieldTimeline?.imageType ?? null;
