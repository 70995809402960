import "ol/ol.css";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {shallowEqual} from "react-redux";
import {useLazyQuery} from "@apollo/client";

import Map from "ol/Map";
import WKT from "ol/format/WKT";
import View from "ol/View";
import Draw from "ol/interaction/Draw";
import Point from "ol/geom/Point";
import Overlay from "ol/Overlay";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import BingMaps from "ol/source/BingMaps";
import TileLayer from "ol/layer/Tile";
import Geolocation from "ol/Geolocation";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import VectorImageLayer from "ol/layer/VectorImage";
import {getArea} from "ol/sphere";
import {Polygon, Circle} from "ol/geom";
import {fromCircle as polygonFromCircle} from "ol/geom/Polygon";
import {Circle as CircleStyle, Style, Fill, Stroke} from "ol/style";
import type BaseEvent from "ol/events/Event";
import type {Geometry} from "ol/geom";
import type {DrawEvent} from "ol/interaction/Draw";
import type {MapBrowserEvent} from "ol";
import type {GeoJSONGeometry} from "ol/format/GeoJSON";
import type {Polygon as GeoJSONPolygon} from "geojson";

import {GET_FIELDS} from "../api/queries";
import {polygonToWkt} from "../../util/functions";
import {BING_MAPS_API_KEY} from "../../config/secrets";
import {useAppDispatch, useAppSelector} from "../../util/hooks";

import {selectType} from "../redux/mapSlice";
import {
  fieldSelected,
  selectAction,
  selectSelectedFarm,
} from "../redux/farmSlice";
import {
  polygonAdded,
  selectTool,
  selectPolygon,
  toolUnselected,
} from "../redux/addFieldSlice";

/* =============================================================================
<MapView />
============================================================================= */
const MapView: React.FC = () => {
  const map = useRef<Map>();
  const addFieldPolygonTooltipRef = useRef<HTMLDivElement>(null);
  const [addFieldPolygonTooltipText, setAnnotationTooltipText] = useState("");

  const dispatch = useAppDispatch();
  const action = useAppSelector(selectAction, shallowEqual);
  const mapType = useAppSelector(selectType, shallowEqual);
  const selectedFarm = useAppSelector(selectSelectedFarm, shallowEqual);
  const addFieldTool = useAppSelector(selectTool, shallowEqual);
  const addFieldPolygon = useAppSelector(selectPolygon, shallowEqual);

  const [getFields, {data: fieldsData}] = useLazyQuery(GET_FIELDS);

  const showAddFieldPolygon = action === "add-field";

  // Get fields
  useEffect(() => {
    if (selectedFarm) {
      getFields({
        variables: {
          where: {
            farmId: {_eq: selectedFarm},
          },
        },
      });
    }
  }, [selectedFarm, getFields]);

  // Read field polygons as features
  const fieldPolygons = useMemo(() => {
    const features: Feature<Geometry>[] = [];

    if (fieldsData?.field_season_shot?.length) {
      fieldsData?.field_season_shot.forEach(field => {
        const geometry = polygonToWkt(field.polygon);

        if (geometry) {
          const feature = new WKT().readFeature(polygonToWkt(field.polygon), {
            dataProjection: "EPSG:4326",
          });

          feature.setProperties({
            id: `${field.id}`,
            name: field.name ?? "",
          });

          features.push(feature);
        }
      });
    }

    return features;
  }, [fieldsData]);

  // Initialize map
  useEffect(() => {
    // Add ol map
    map.current = new Map({
      target: "HomeMap",
      layers: [
        // Base layer (map)
        new TileLayer({
          source: new BingMaps({
            key: BING_MAPS_API_KEY,
            imagerySet: "RoadOnDemand",
          }),
          visible: false,
        }),
        // Base layer (satellite)
        new TileLayer({
          source: new BingMaps({
            key: BING_MAPS_API_KEY,
            imagerySet: "AerialWithLabelsOnDemand",
          }),
          visible: false,
        }),
        // Field Polygons layer
        new VectorImageLayer({
          style: fieldPolygonsStyle,
          source: new VectorSource(),
          imageRatio: 2,
        }),
        // Add field Polygon layer
        new VectorImageLayer({
          style: addFieldPolygonStyle,
          source: new VectorSource(),
          imageRatio: 2,
        }),
        // Draw add field polygon layer
        new VectorLayer({
          style: addFieldPolygonStyle,
          source: new VectorSource(),
        }),
      ],
      view: new View({
        center: [-98.5795, 39.8282],
        zoom: 16,
        maxZoom: 20,
        projection: "EPSG:4326",
      }),
      controls: [],
    });

    // Hover overlay on features
    const featureOverlay = new VectorLayer({
      source: new VectorSource(),
      map: map.current,
      style: featureOverlayStyle,
    });

    /**
     *  Add click event listener
     */

    const _handleMapClick = (event: MapBrowserEvent<never>) => {
      const feature = map.current?.forEachFeatureAtPixel(
        event.pixel,
        ftr => ftr,
      );

      // Get field id from feature props
      const fieldId = feature?.getProperties().id;

      if (fieldId) {
        // Select field
        dispatch(fieldSelected(fieldId));
      }
    };

    map.current.on("click", _handleMapClick);

    /**
     * Add pointer move event listener
     */

    let _highlightedFeature: Feature<Geometry> | undefined;

    const _handleMapPointerMove = (event: MapBrowserEvent<never>) => {
      if (event.dragging) {
        return;
      }

      const feature = map.current?.forEachFeatureAtPixel(
        event.pixel,
        ftr => ftr,
      ) as Feature<Geometry> | undefined;

      // Add hover effect on features
      if (feature !== _highlightedFeature) {
        if (_highlightedFeature) {
          featureOverlay.getSource()?.removeFeature(_highlightedFeature);
        }
        if (feature) {
          featureOverlay.getSource()?.addFeature(feature);
        }
        _highlightedFeature = feature;
      }
    };

    map.current.on("pointermove", _handleMapPointerMove);

    return () => {
      map.current?.removeEventListener("click", _handleMapClick);
      map.current?.dispose();
    };
  }, [dispatch]);

  // Set base layer
  useEffect(() => {
    if (mapType === "map") {
      // Get base layers
      const [mapLayer, satelliteLayer] = map.current?.getAllLayers() ?? [];

      // Update base layers
      mapLayer?.setVisible(true);
      satelliteLayer?.setVisible(false);
    }

    if (mapType === "satellite") {
      // Get base layers
      const [mapLayer, satelliteLayer] = map.current?.getAllLayers() ?? [];

      // Update base layers
      satelliteLayer?.setVisible(true);
      mapLayer?.setVisible(false);
    }
  }, [mapType]);

  // Set field polygons layer
  useEffect(() => {
    // Get vector source
    const source = map.current
      ?.getAllLayers()[2]
      .getSource() as VectorSource | null;

    // Reset vector source
    source?.clear();

    if (fieldPolygons.length) {
      // Update vector source
      source?.addFeatures(fieldPolygons);

      // Get source extent
      const extent = source?.getExtent();

      if (extent) {
        // Fit upload points on map
        map.current?.getView().fit(extent, {
          padding: [100, 100, 100, 100],
        });
      }
    }
  }, [fieldPolygons]);

  // Set add field polygons layer
  useEffect(() => {
    // Get vector source
    const source = map.current
      ?.getAllLayers()[3]
      .getSource() as VectorSource | null;

    // Reset vector source
    source?.clear();

    if (showAddFieldPolygon && addFieldPolygon) {
      // Update vector source
      // @ts-expect-error GeoJSON().readFeature return type should not be an array
      source?.addFeature(new GeoJSON().readFeature(addFieldPolygon));

      // Get source extent
      const extent = source?.getExtent();

      if (extent) {
        // Fit upload points on map
        map.current?.getView().fit(extent, {
          padding: [100, 100, 100, 100],
        });
      }
    }

    if (!showAddFieldPolygon && !addFieldPolygon) {
      // Set map view to field polygons extent
      if (fieldPolygons.length) {
        map.current
          ?.getView()
          .fit(new VectorSource({features: fieldPolygons}).getExtent(), {
            padding: [100, 100, 100, 100],
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddFieldPolygon, addFieldPolygon]); // Exclude "fieldPolygons"

  // Update draw add field polygon layer on polygon remove
  useEffect(() => {
    if (addFieldPolygon === null) {
      // Get draw add field polygon layer source
      const source = map.current?.getAllLayers()[4].getSource() as VectorSource;

      // Remove features from draw add field polygon
      source?.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFieldPolygon]);

  // Add map interaction according to tool selected
  useEffect(() => {
    // Get draw add field polygon layer source
    const source = map.current?.getAllLayers()[4].getSource() as VectorSource;

    // Remove draw add field polygon
    source?.clear();

    if (addFieldTool) {
      // Create draw interaction
      const draw = new Draw({
        source,
        type: addFieldTool,
        condition: event => {
          // Remove last point on right click
          if (
            event.originalEvent.pointerType === "mouse" &&
            event.originalEvent.button === 2
          ) {
            draw.removeLastPoint();
            return false;
          }

          return true;
        },
      });

      // Create draw add field polygon tooltip overlay
      const tooltipOverlay = new Overlay({
        element: addFieldPolygonTooltipRef.current ?? undefined,
        offset: [0, -15],
        positioning: "bottom-center",
        stopEvent: false,
        insertFirst: false,
      });

      // Reference of currently drawn geometry
      let drawGeometry: Geometry | undefined;

      const _handleDrawGeometryChange = (event: BaseEvent) => {
        const geom = event.target;

        if (geom instanceof Polygon) {
          setAnnotationTooltipText(formatArea(geom));

          tooltipOverlay?.setPosition(geom.getInteriorPoint().getCoordinates());
        }

        if (geom instanceof Circle) {
          setAnnotationTooltipText(formatArea(polygonFromCircle(geom)));

          tooltipOverlay?.setPosition(geom.getCenter());
        }
      };

      const _handleDrawStart = (event: DrawEvent) => {
        // Add tooltip overlay
        map.current?.addOverlay(tooltipOverlay);

        // Get feature geometry
        drawGeometry = event.feature.getGeometry();

        // Add change event listener on draw geometry
        drawGeometry?.on("change", _handleDrawGeometryChange);
      };

      const _handleDrawAbort = () => {
        // Remove tooltip overlay
        map.current?.removeOverlay(tooltipOverlay);
      };

      const _handleDrawEnd = (event: DrawEvent) => {
        // Remove tooltip overlay
        map.current?.removeOverlay(tooltipOverlay);

        // Get feature geometry
        const finalDrawGeometry = event.feature.getGeometry();

        if (finalDrawGeometry) {
          const format = new GeoJSON();
          const geometryType = finalDrawGeometry.getType();

          // Get GeoJSON representation
          let parsedGeometry: GeoJSONGeometry | undefined;

          if (geometryType === "Circle") {
            parsedGeometry = format.writeGeometryObject(
              // Convert circle to polygon
              polygonFromCircle(finalDrawGeometry as Circle),
            );
          }

          if (geometryType === "Polygon") {
            parsedGeometry = format.writeGeometryObject(finalDrawGeometry);
          }

          if (parsedGeometry) {
            dispatch(polygonAdded(parsedGeometry as GeoJSONPolygon));
          }
        }

        // Unselect tool
        dispatch(toolUnselected());
      };

      // Add draw interaction
      draw.on("drawstart", _handleDrawStart);
      draw.on("drawabort", _handleDrawAbort);
      draw.on("drawend", _handleDrawEnd);
      map.current?.addInteraction(draw);

      // Remove interaction on "ESC" press
      const _handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          // Abort drawing
          draw.abortDrawing();
        }
        if (event.key === "Delete") {
          // Abort drawing
          draw.abortDrawing();

          // Unselect tool
          dispatch(toolUnselected());
        }
      };
      window.addEventListener("keyup", _handleKeyUp);

      return () => {
        // Remove listeners
        drawGeometry?.removeEventListener("change", _handleDrawGeometryChange);
        draw.removeEventListener("drawstart", _handleDrawStart);
        draw.removeEventListener("drawabort", _handleDrawAbort);
        draw.removeEventListener("drawend", _handleDrawEnd);
        window.removeEventListener("keyup", _handleKeyUp);

        // Remove interaction
        map.current?.removeInteraction(draw);
      };
    }
  }, [addFieldTool, dispatch]);

  // Add user location layer
  useEffect(() => {
    // Create geolocation features
    const accuracyFeature = new Feature();
    const positionFeature = new Feature();

    // Add styles to geolocation features
    positionFeature.setStyle(locationPositionStyle);

    // Create geolocation config
    const geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: map.current?.getView().getProjection(),
    });

    // Update location on accuracy change
    const _handleLocationAccuracyChange = () => {
      const geometry = geolocation.getAccuracyGeometry();

      accuracyFeature.setGeometry(geometry ?? undefined);
    };

    geolocation.on("change:accuracyGeometry", _handleLocationAccuracyChange);

    // Update location on position change
    const _handleLocationPositionChange = () => {
      const coordinates = geolocation.getPosition();

      positionFeature.setGeometry(
        coordinates ? new Point(coordinates) : undefined,
      );
    };

    geolocation.on("change:position", _handleLocationPositionChange);

    // Create new vector layer as overlay
    new VectorLayer({
      map: map.current,
      source: new VectorSource({
        features: [accuracyFeature, positionFeature],
      }),
    });
  }, []);

  return (
    <div className="w-full h-full relative">
      <div id="HomeMap" className="w-full h-full z-0" />
      <div
        ref={addFieldPolygonTooltipRef}
        className={`${addFieldPolygonTooltipText ? "block" : "hidden"} p-2 bg-[rgba(0,0,0,0.5)] rounded-md text-xs text-white`}>
        {addFieldPolygonTooltipText}
      </div>
    </div>
  );
};

/**
 * Styles
 */

const fieldPolygonsStyle = {
  "fill-color": "rgba(252, 85, 0, 0.4)",
  "stroke-color": "rgba(252, 85, 0, 0.6)",
  "stroke-width": 3,
  "text-font": "500 12px Poppins,sans-serif",
  "text-value": ["string", ["get", "name"], ""],
  "text-overflow": true,
  "text-fill-color": "#ffffff",
};

const locationPositionStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: "#3399CC",
    }),
    stroke: new Stroke({
      color: "#fff",
      width: 2,
    }),
  }),
});

const addFieldPolygonStyle = {
  "fill-color": "rgba(244, 247, 79, 0.4)",
  "stroke-color": "rgba(244, 247, 79, 0.6)",
  "stroke-width": 3,
  "circle-fill-color": "rgba(244, 247, 79, 0.6)",
  "circle-stroke-width": 3,
};

const featureOverlayStyle = {
  "stroke-color": "rgba(255, 255, 255, 0.7)",
  "stroke-width": 3,
};

/**
 * Helper functions
 */

const formatArea = function (polygon) {
  const area = getArea(polygon, {
    projection: "EPSG:4326",
  });

  return `${((Math.round(area * 100) / 100) * 0.000247105).toFixed(2)} acres`;
};

/* Export
============================================================================= */
export default MapView;
